<template>
	<v-sheet>
		<currently-recruiting-tile v-for="(tile, id) in tiles" :key="id" :id="id" :title="tile.title"></currently-recruiting-tile>
		<secondary-navigation show-back>
          
        </secondary-navigation>
	</v-sheet>
</template>

<script>
import SecondaryNavigation from "@c/navigation/Secondary";
import CurrentlyRecruitingTile from "@c/edits/CurrentlyRecruitingTile";

export default {
	name: "CurrentlyRecruiting",
    computed: {
        tiles(){
			return this.$store.state.config.data.currently_recruiting;
        }
    },
    components: {
		SecondaryNavigation,
        CurrentlyRecruitingTile
	}
}
</script>